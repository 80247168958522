<template>
  <b-card
      :img-src="egirl.avatar.url"
      class="egirl-card rounded"
      overlay
      @click="goToUrl(egirl.twitterHandle)"
      @mousedown.middle="goToUrl(egirl.twitterHandle)"
  >
    <span>
      <img class="egirl-live" src="../assets/live2.gif" alt="currently live"/>
    </span>
    <span class="egirl-link">
      {{`@${egirl.twitterHandle}`}}
    </span>
  </b-card>
</template>

<script>

export default {
  name: 'TwitterCards',
  props: {
    egirl: {
      required: true,
      type: Object
    }
  },
  methods: {
    goToUrl(url) {
      window.open(`https://${this.egirl.socialMediaLink === 'twitter' ? 'twitter.com' : 'twitch.tv'}/${url}`).focus()
    }
  }
}
</script>

<style scoped lang="scss">
@import '../styles/_variables.scss';

.egirl-card {
  border: 4px solid $regular-pink;
  background-color: $regular-pink;
  overflow: hidden;
  max-width: 12.2rem;
  cursor: pointer;

  .egirl-link {
    font-family: 'Anonymous Pro', monospace;
    font-size: 1.2em;
    color: $white;
    position: absolute;
    left: 0;
    bottom: 0;
    padding: 6px 12px;
    background-color: $regular-pink;
  }

  .egirl-live {
    position: absolute;
    top: -4px;
    right: -4px;
    border: 4px solid $regular-pink;
    background-color: $regular-pink;
  }

  &:hover {
    border-color: $light-pink;
    .egirl-link {
      background-color: $light-pink;
    }
  }
}
</style>
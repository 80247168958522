export default {
  taglines: [
    'If you\'re missing a sock, chances are we got it.',
    'Loaded with memes of mass destruction.',
    'Verifiably not glowing in the dark.',
    'We believe in blockchain and decentralization.',
    '↑ ↑ ↓ ↓ ← → ← → B A',
    'Funding blockchain projects since 2019.',
    'Meming tokens into diamonds.',
    'To become an eQueen one must start as an eGirl.'
  ],
  egirls: [],
  egirlsTimestamp: null,
  positions: [],
  live: [],
  collectibles: [],
  collectiblesTimestamp: null,
  totalCollectibles: 0,
  fetchedCollectibles: 0,
  investments: null,
  investmentsTimestamp: null,
  investment: null,
  writings: null,
  writingsTimestamp: null,
  writing: null,
  // magazines: null,
  // magazinesTimestamp: null,
  // magazine: null
}

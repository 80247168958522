import axios from 'axios'

const apiError = 'HTTP Request failed'
const clientId = process.env.VUE_APP_TWITCH_CLIENT_TOKEN
const clientSecret = process.env.VUE_APP_TWITCH_SECRET_TOKEN

const datoCmsCall = query => {
  return {
    url: 'https://graphql.datocms.com/',
    method: 'POST',
    headers: {
      'Authorization': process.env.VUE_APP_DATOCMS_API_TOKEN
    },
    data: {
      query: query
    }
  }
}

const openSeaCall = (contract, tokenid) => {
  return {
    url: `https://api.opensea.io/api/v1/asset/${contract}/${tokenid}`,
    method: 'GET',
    headers: {
      'X-API-KEY': process.env.VUE_APP_OPENSEA_API_TOKEN
    }
  }
}

const twitchTokenCall = () => {
  /* calls twitch for an access token for the api */
  return {
    url: `https://id.twitch.tv/oauth2/token?client_id=${clientId}&client_secret=${clientSecret}&grant_type=client_credentials`,
    method: 'POST'
  }
}

const twitchLiveCall = (name, token) => {
  /* calls twitch api to check the status of a streamer */
  return {
    url: `https://api.twitch.tv/helix/streams?user_login=${name}`,
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Client-Id': clientId
    }
  }
}

export default {

  queryEgirls (context) {
    const refresh = (Date.now() - context.state.egirlsTimestamp)/86400000 >= 1
    if (context.state.egirls.length === 0 || refresh) {
      const query = `{
        allEgirls(first: 100) {
          nickname
          position
          teamPosition
          avatar {
            url
          }
          socialMediaLink
          twitterHandle
        }
      }`
      axios(datoCmsCall(query))
        .then(response => {
          const positions = []
          const currentlyLive = []
          response.data.data.allEgirls.forEach(egirl => {
            if (!positions.includes(egirl.teamPosition)) {
              positions.push(egirl.teamPosition)
            }
            axios(twitchTokenCall()).then(response => {
              const accessToken = response.data.access_token
              if (egirl.teamPosition === 'streamers') {
                axios(twitchLiveCall(egirl.twitterHandle, accessToken))
                    .then(response => {
                      if (response.data.data[0]) {
                        currentlyLive.push(egirl)
                      }
                    })
              }
            })
          })
          context.commit('writePositions', positions)
          context.commit('writeLive', currentlyLive)
          context.commit('writeEgirls', response.data.data.allEgirls)
        })
        .catch(error => {
          console.log(apiError, error)
        })
    }
  },

  resetAssets (context) {
    context.commit('clearCollectiblesTimestamp')
    context.commit('clearCollectible')
    context.commit('clearFetchedCollectibles')
  },

  queryAssets (context) {
    console.log(context.state.collectibles.length)
    const refresh = (Date.now() - context.state.collectiblesTimestamp)/86400000 >= 1
    if (context.state.collectibles.length === 0 || refresh) {
      context.dispatch('resetAssets')
      let collectibles
      // let openSeaList = []
      const query = `{
        allCollectibles(first: 100) {
          apiProviderAvailable
          title
          tokenid
          contract
          collection
          provider
          permalink
          position
          addPermalink
          sortingPriority
          preview {
            mimeType
            url
            video {
              thumbnailUrl
            }
          }
        }
      }`
      axios(datoCmsCall(query))
        .then(response => {
          collectibles = response.data.data.allCollectibles
          context.commit('updateTotalCollectibles', collectibles.length)
          let counter = 0
          collectibles.forEach(collectible => {
            Object.assign(collectible, {
              retry: 0
            })
            counter += 10
            if (collectible.apiProviderAvailable) {
              // @dev we need to enrich the object with a few more keys
              Object.assign(collectible, {
                preview: {
                  mimeType: null,
                  url: null,
                  video: {
                    thumbnailUrl: null
                  }
                }
              })
              window.setTimeout(() => {
                context.dispatch('openSeaRetry', collectible)
              }, counter)
            } else {
              context.commit('writeCollectible', collectible)
              context.commit('updateFetchedCollectibles')
            }
          })
        })
        .catch(error => {
          console.log(apiError, error)
        })
    }
  },

  async openSeaRetry (context, collectible) {
    axios(openSeaCall(collectible.contract, collectible.tokenid))
      .then(response => {
        collectible.collection = response.data.collection.name
        collectible.permalink = collectible.addPermalink ?
          response.data.permalink :
          null
        collectible.preview.url = response.data.animation_url === null ?
          response.data.image_preview_url :
          response.data.animation_url
        collectible.preview.mimeType = response.data.animation_url === null ?
          'image/jpeg' :
          'video/mp4'
        collectible.title = response.data.name
        context.commit('writeCollectible', collectible)
        context.commit('updateFetchedCollectibles')
      })
      .catch(error => {
        // console.log(`RETRIES: ${collectible.retry}`)
        if (collectible.retry >= 3) {
          console.log(apiError, error)
          collectible.collection = 'Network Error'
          collectible.permalink = `https://opensea.io/assets/${collectible.contract}/${collectible.tokenid}`
          collectible.preview.url = 'https://www.datocms-assets.com/45756/1618252753-egirl-missing.png'
          collectible.preview.mimeType = 'image/jpeg'
          collectible.title = 'Click to open source'
          context.commit('writeCollectible', collectible)
          context.commit('updateFetchedCollectibles')
        } else {
          collectible.retry += 1
          // console.log("RETRYING INSIDE openSeaRetry")
          context.dispatch('openSeaRetry', collectible)
        }
      })
  },

  createTimestamp (context, timestamp) {

    switch (timestamp) {
      case 'writings':
        if (context.state.writingsTimestamp === null) {
          context.commit('updateWritingsTimestamp')
        }
        break
      case 'investments':
        if (context.state.investmentsTimestamp === null) {
          context.commit('updateInvestmentsTimestamp')
        }
        break
      case 'collectibles':
        if (context.state.collectiblesTimestamp === null) {
          context.commit('updateCollectiblesTimestamp')
        }
        break
      case 'egirls':
      default:
        if (context.state.egirlsTimestamp === null) {
          context.commit('updateEgirlsTimestamp')
        }
        break
    }
  },

  // TODO refactor *Investemt and *Writing so they share a function each
  // TODO refactor so it doesn't query the API anymore, but checks local state
  queryInvestment (context, investmentName) {
    const options = `
          {
            investment(filter: {investmentTitle: {eq: ${investmentName}}}) {
              author
              title
              createdAt
              investmentLogo {
                url
              }
              content {
                ... on ImageRecord {
                  id
                  image {
                    url
                  }
                }
                ... on TextRecord {
                  id
                  text {
                    value
                  }
                }
              }
            }
          }
        `
    axios(datoCmsCall(options))
      .then(response => {
        context.commit('writeInvestment', response.data.data.investment)
      })
      .catch(error => {
        console.log(apiError, error)
      })
  },

  // TODO remove after above and below are refactored
  clearInvestment (context) {
    context.commit('clearInvestment')
  },

  queryInvestments (context) {
    const refresh = (Date.now() - context.state.investmentsTimestamp)/86400000 >= 1
    const options = `
          {
            allInvestments {
              investmentTitle,
              investmentLogo {
                  url
              },
              id
            }
          }
        `
    if (context.state.investments === null || context.state.investments.length === 0 || refresh) {
      axios(datoCmsCall(options))
        .then(response => {
          context.commit('writeInvestments', response.data.data.allInvestments)
        })
        .catch(error => {
          console.log(apiError, error)
        })
    }
  },

  // TODO refactor so it doesn't query the API anymore, but checks local state
  queryWritings (context) {
    const refresh = (Date.now() - context.state.writingsTimestamp)/86400000 >= 1
    const options = `
        {
          allWritings(first: 100) {
            id
            title,
            createdAt,
            author
          }
        }
        `
    if (context.state.writings === null || context.state.writings.length === 0 || refresh) {
      axios(datoCmsCall(options))
        .then(response => {
          const sortedWritings = response.data.data.allWritings.sort((a, b) => a.createdAt - b.createdAt)
          context.commit('writeWritings', sortedWritings)
        })
        .catch(error => {
          console.log(apiError, error)
        })
    }
  },

  // TODO remove after above and below are refactored
  clearWriting (context) {
    context.commit('clearWriting')
  },

  // TODO refactor so it also grabs the full content of each item
  queryWriting (context, payload) {
    const options = `
          {
          writing(filter: {id: {eq: ${payload}}}) {
            title,
            createdAt,
            author,
            richcontent {
              ... on ImageRecord {
                id
                image {
                  url
                }
              }
              ... on TextRecord {
                id
                text {
                  value
                }
              }
            }
          }
        }
        `
    axios(datoCmsCall(options))
      .then(response => {
        context.commit('writeWriting', response.data.data.writing)
      })
      .catch(error => {
        console.log(apiError, error)
      })
  },

  // // TODO refactor so it doesn't query the API anymore, but checks local state
  // queryMagazines (context) {
  //   const refresh = (Date.now() - context.state.magazinesTimestamp)/86400000 >= 1
  //   const options = `
  //       {
  //         allMagazines(first: 100) {
  //           id
  //           title,
  //           createdAt,
  //           author
  //         }
  //       }
  //       `
  //   if (context.state.magazines === null || context.state.magazines.length === 0 || refresh) {
  //     axios(datoCmsCall(options))
  //         .then(response => {
  //           const sortedMagazines = response.data.data.allMagazines.sort((a, b) => a.createdAt - b.createdAt)
  //           context.commit('writeMagazines', sortedMagazines)
  //         })
  //         .catch(error => {
  //           console.log(apiError, error)
  //         })
  //   }
  // },
  //
  // // TODO remove after above and below are refactored
  // clearMagazine (context) {
  //   context.commit('clearMagazine')
  // },
  //
  // // TODO refactor so it also grabs the full content of each item
  // queryMagazine (context, payload) {
  //   const options = `
  //         {
  //         magazine(filter: {id: {eq: ${payload}}}) {
  //           title,
  //           createdAt,
  //           author,
  //           epub {
  //             url
  //           },
  //           pdf {
  //             url
  //           }
  //         }
  //       }
  //       `
  //   axios(datoCmsCall(options))
  //       .then(response => {
  //         context.commit('writeMagazine', response.data.data.magazine)
  //       })
  //       .catch(error => {
  //         console.log(apiError, error)
  //       })
  // },

}

export default {
  writeCollectible: (state, payload) => {
    state.collectibles.push(payload)
  },
  clearCollectible: state => {
    state.collectibles = []
  },
  writeInvestment: (state, payload) => {
    state.investment = payload
  },
  clearInvestment: state => {
    state.investment = null
  },
  writeInvestments: (state, payload) => {
    state.investments = payload
  },
  writeWritings: (state, payload) => {
    state.writings = payload
  },
  writeWriting: (state, payload) => {
    state.writing = payload
  },
  clearWriting: state => {
    state.writing = null
  },
  writeEgirls: (state, payload) => {
    state.egirls = payload
  },
  writePositions: (state, payload) => {
    state.positions = payload
  },
  writeLive: (state, payload) => {
    state.live = payload
  },
  // writeMagazines: (state, payload) => {
  //   state.magazines = payload
  // },
  // writeMagazine: (state, payload) => {
  //   state.magazine = payload
  // },
  // clearMagazine: state => {
  //   state.magazine = null
  // },
  updateTotalCollectibles: (state, payload) => {
    state.totalCollectibles = payload
  },
  updateFetchedCollectibles: state => {
    state.fetchedCollectibles += 1
  },
  clearFetchedCollectibles: state => {
    state.fetchedCollectibles = 0
  },
  updateEgirlsTimestamp: state => {
    state.egirlsTimestamp = Date.now()
  },
  clearEgirlsTimestamp: state => {
    state.egirlsTimestamp = null
  },
  updateCollectiblesTimestamp: state => {
    state.collectiblesTimestamp = Date.now()
  },
  clearCollectiblesTimestamp: state => {
    state.collectiblesTimestamp = null
  },
  updateInvestmentsTimestamp: state => {
    state.investmentsTimestamp = Date.now()
  },
  clearInvestmentsTimestamp: state => {
    state.investmentsTimestamp = null
  },
  updateWritingsTimestamp: state => {
    state.writingsTimestamp = Date.now()
  },
  clearWritingsTimestamp: state => {
    state.writingsTimestamp = null
  // },
  // updateMagazines: state => {
  //   state.magazinesTimestamp = Date.now()
  // },
  // clearMagazinesTimestamp: state => {
  //   state.magazinesTimestamp = null
  }
}

export default {
  getTaglines: state => {
    return state.taglines
  },
  getEgirls: state => {
    return state.egirls
  },
  getPositions: state => {
    return state.positions
  },
  getLive: state => {
    return state.live
  },
  getCollectibles: state => {
    return state.collectibles
  },
  getInvestment: state => {
    return state.investment
  },
  getInvestments: state => {
    return state.investments
  },
  getWritings: state => {
    return state.writings
  },
  getWriting: state => {
    return state.writing
  },
  getTotalCollectibles: state => {
    return state.totalCollectibles
  },
  getFetchedCollectibles: state => {
    return state.fetchedCollectibles
  },
  // getMagazines: state => {
  //   return state.magazines
  // },
  // getMagazine: state => {
  //   return state.magazine
  // },
  apiCallsFinished: state => {
    return state.fetchedCollectibles === state.totalCollectibles
  }
}

import Vue from 'vue'
import VueRouter from 'vue-router'
import home from '../views/home/home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: home
  },
  {
    path: '/writings',
    name: 'Writings',
    component: () => import(/* webpackChunkName: "writings" */ '../views/writings/writings.vue'),
  },
  {
    path: '/writings/:writing',
    name: 'Writing',
    component: () => import(/* webpackChunkName: "writing" */ '../views/writings/writing.vue'),
  },
  {
    path: '/portfolio',
    name: 'Portfolio',
    component: () => import(/* webpackChunkName: "portfolio" */ '../views/portfolio/portfolio.vue')
  },
  {
    path: '/portfolio/:investment',
    name: 'Investment',
    component: () => import(/* webpackChunkName: "investment" */ '../views/portfolio/investment.vue')
  },
  // {
  //   path: '/art_stuff',
  //   name: 'Art Stuff',
  //   component: () => import(/* webpackChunkName: "collectibles" */'../views/collectibles/collectibles.vue')
  // },
  // {
  //   path: '/art_stuff/:art',
  //   name: 'Art',
  //   component: () => import(/* webpackChunkName: "collectible" */'../views/collectibles/collectible.vue')
  // },
  // {
  //   path: '/magazines',
  //   name: 'Magazines',
  //   component: () => import(/* webpackChunkName: "magazines" */'../views/magazines/magazines.vue')
  // },
  // {
  //   path: '/magazines/:magazine',
  //   name: 'Magazine',
  //   component: () => import(/* webpackChunkName: "magazine" */'../views/magazines/magazine.vue')
  // },
  // @dev adding some error handling here
  {
    path: '/error/:code',
    name: 'Error',
    component: () => import(/* webpackChunkName: "error" */ '../views/error.vue')
  },
  {
    path: '/*',
    redirect: '/error/404'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if(savedPosition) {
      return savedPosition
    }
    else if (to.hash) {
      return {
        selector: to.hash
      }
    }
    else {
      return {
        x: 0,
        y: 0
      }
    }
  }
})

export default router

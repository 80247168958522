<template>
  <div
    id="nav-overlay"
    class="container-fluid sticky-top"
  >
    <div class="row">
      <div class="col text-center mt-5">
        <b-img
          :src="require('../../assets/egirl-mobile.png')"
          width="200px"
          @click="closeOverlay"
        />
      </div>
    </div>

    <ul class="mt-5 pl-0">
      <li>
        <a
          href="javascript:void(0)"
          @click="moveUser('Home')"
        >
          00.home
        </a>
      </li>
      <li>
        <a
          href="javascript:void(0)"
          @click="moveUser('Writings')"
        >
          01.writings
        </a>
      </li>
      <li>
        <a
          href="javascript:void(0)"
          @click="moveUser('Portfolio')"
        >
          02.portfolio
        </a>
      </li>
      <!-- <li>
        <a
          href="javascript:void(0)"
          @click="moveUser('Art Stuff')"
        >
          03.art stuff
        </a>
      </li> -->
     <!-- <li>
       <a
         href="javascript:void(0)"
         @click="moveUser('Magazines')"
       >
         04.magazines
       </a>
     </li> -->
      <li v-if="debug">
        <a
          href="javascript:void(0)"
          @click="clearVuex">
          @1.clear vuex
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'NavOverlay',
  methods: {
    closeOverlay () {
      this.$emit('closeOverlay')
    },
    moveUser (name) {
      this.$emit('moveUser', name)
    },
    clearVuex () {
      localStorage.clear()
    }
  },
  computed: {
    debug () {
      return process.env.VUE_APP_DEBUG === 'true'
    }
  }
}
</script>

<style scoped lang="scss">
@import '../../styles/_variables';

#nav-overlay {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1500;
  height: 100vh;
  width: 100vw;
  background-color: $dark-grey;

  ul>li {
    font-family: 'Anonymous Pro', monospace;
    display: block;
    margin-bottom: 15px;

    a {
      padding: 8px 24px;
      font-size: 2rem;
      color: $white;

      &:hover {
        text-decoration: none;
        padding-left: 19px;
        border-left: 5px solid $regular-pink;
      }
    }
  }
}
</style>
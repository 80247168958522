<template>
  <div id="home" class="container">
    <div class="mb-4 d-none d-xl-block">
      <p class="logo mb-0">
        仙女資本
      </p>
      <p class="logo">
        What is...
        <a class="button" href="#popup1">eGirl Capital?</a>
      </p>
      <div id="popup1" class="overlay light">
        <a class="cancel" href="#"></a>
        <div class="popup">
          <div id="img-container">
            <img id="cat" src="../../assets/cl.gif" alt="yueko and cat">
          </div>
          <p>
            <b>What is eGirl Capital?</b>
          </p>
          <p>
            a digital native brand, a joke, a venture capital … we’ve become many things
          </p>
          <p>
            i am CL, a cat in yellow hazmat suit, and here i'll introduce eGirl capital from my view
          </p>

          <p>
            <b>How did it come about?</b>
          </p>
          <p>
            sometime in later half of 2020, markets were recovering from the covid crash, le cat made a meme on how itd be so absurd if a buncha weebs made a fund called “eGirl capital” that invested alongside well established venture funds. soon, a group chat was made, we talked about our lives, journeys, and jokes, and the rest was history.
          </p>
          <p>
            we still dont know most peoples names, what they actually look like, later invited interns we vibed with, hoping that will get them bfs/gfs, it's been great fun driving the trends of deprofessionalism
          </p>

          <p>
            <b>What we do:</b>
          </p>
          <p>
            we are a group of friends, we do different things, and different roles, majority can be summarized as: trading, investing, thinking, software thingies, fudding, reading anime, and having fun.
          </p>
          <p>
            ze hazmat cat personally likes trading futures, but unfortunately have little knowledge of blockchain and defi’s actual inner workings. cat's teammates do other great things, le example, the couch (mewny) does a lot of cutting research in defi, some of us are software chads and have built cutting edge finance products. we collectively invest in startups and projects, especially ones that are in crypto. we gud at investing.
          </p>
          <p>
            we also like art, cat has personally commissioned or directly supported (eg providing art equipments) many hundreds of artists, and sponsored many other things cat likes.
            others members at eGirl have also supported really really great things we want to see created
          </p>

          <p>
            <b>Where it goes...</b>
          </p>
          <p>
            we will continue what we are already doing but other than dat, dosnt know lmeow, cartoon characters taking over the interwebs sounds fun ^^
          </p>

          <p>
            <b>Read more:</b>
          </p>
          <a href="https://www.bloomberg.com/news/articles/2022-02-03/eva-beylin-on-egirl-capital-the-graph-and-building-web3" target="_blank" rel="noopener">
            <img id="news" src="../../assets/article.png" alt="egirl article">
          </a>
        </div>
      </div>
    </div>
    <div class="mb-4 d-xl-none d-sm-block">
      <h2>
        仙女資本
        <br>
        What is...
          <a class="button" href="#popup2">eGirl Capital?</a>
      </h2>
      <div id="popup2" class="overlay light">
        <a class="cancel" href="#"></a>
        <div class="popup-mobile">
          <div id="img-container-mobile">
            <img id="cat-mobile" src="../../assets/cl.gif" alt="yueko and cat">
          </div>
          <p>
            <b>Who are we?</b>
          </p>
          <p>
            a digital native brand, a joke, a venture capital … we’ve become many things
          </p>
          <p>
            i am CL, a cat in yellow hazmat suit, and here i'll introduce eGirl capital from my view
          </p>

          <p>
            <b>Our origins?</b>
          </p>
          <p>
            sometime in later half of 2020, markets were recovering from the covid crash, le cat made a meme on how itd be so absurd if a buncha weebs made a fund called “eGirl capital” that invested alongside well established venture funds. soon, a group chat was made, we talked about our lives, journeys, and jokes, and the rest was history.
          </p>
          <p>
            we still dont know most peoples names, what they actually look like, later invited interns we vibed with, hoping that will get them bfs/gfs, it's been great fun driving the trends of deprofessionalism
          </p>

          <p>
            <b>What we do:</b>
          </p>
          <p>
            we are a group of friends, we do different things, and different roles, majority can be summarized as: trading, investing, thinking, software thingies, fudding, reading anime, and having fun.
          </p>
          <p>
            ze hazmat cat personally likes trading futures, but unfortunately have little knowledge of blockchain and defi’s actual inner workings. cat's teammates do other great things, le example, the couch (mewny) does a lot of cutting research in defi, some of us are software chads and have built cutting edge finance products. we collectively invest in startups and projects, especially ones that are in crypto. we gud at investing.
          </p>
          <p>
            we also like art, cat has personally commissioned or directly supported (eg providing art equipments) many hundreds of artists, and sponsored many other things cat likes.
            others members at eGirl have also supported really really great things we want to see created
          </p>

          <p>
            <b>Where it goes...</b>
          </p>
          <p>
            we will continue what we are already doing but other than dat, dosnt know lmeow, cartoon characters taking over the interwebs sounds fun ^^
          </p>
          <p>
            <b>Read more:</b>
          </p>
          <a href="https://www.bloomberg.com/news/articles/2022-02-03/eva-beylin-on-egirl-capital-the-graph-and-building-web3" target="_blank" rel="noopener">
            <img id="news-mobile" src="../../assets/article.png" alt="egirl article">
          </a>
        </div>
      </div>
    </div>

    <div class="row mt-4 pl-3" v-for="position in getPositions" :key="position">
      <div
          class="top-divider col-12 mb-5"
      >
        <hr>
        <p class="divider-label next pl-0">
          {{position}}
        </p>
      </div>
      <div
          v-for="egirl in sortEgirl(position)"
          :key="egirl.position"
          class="col-6 col-lg-auto mb-4 pl-0"
      >
        <LiveCard
            v-if="getLive.includes(egirl)"
            :egirl="egirl"
        />
        <TwitterCard
            v-else
            :egirl="egirl"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import TwitterCard from '@/components/TwitterCard'
import LiveCard from '@/components/LiveCard'

export default {
  name: 'EgirlHome',
  components: {
    TwitterCard,
    LiveCard
  },
  methods: {
    ...mapActions(['queryEgirls', 'createTimestamp']),
    sortEgirl(teamPosition) {
      const sorted = []
      this.getEgirls.forEach(item => {
        if (item.teamPosition === teamPosition) {
          sorted.push(item)
        }
      })
      return sorted
    },
  },
  computed: {
    ...mapGetters([
      'getEgirls',
      'getPositions',
      'getLive'
    ])
  },
  async mounted() {
    this.createTimestamp('egirls')
    await this.queryEgirls()
  }
}
</script>

<style scoped lang="scss">
@import '../../styles/_variables.scss';

#home {
  .logo {
    font-family: 'Oswald', sans-serif;
    font-size: 5rem;
    line-height: 5rem;
    text-transform: uppercase;
    padding-left: 19px;
    border-left: 5px solid $regular-pink;

    &:first-child {
      margin-top: .2rem;
    }
  }

  h2 {
    font-size: 2rem;
    line-height: 2rem;
    font-family: 'Oswald', sans-serif;
    font-weight: normal;
    text-transform: uppercase;
    padding-left: 19px;
    border-left: 5px solid $regular-pink;

    &:first-child {
      margin-top: .2rem;
    }

    span {
      color: $regular-pink;
    }
  }

  hr {
    background-color: $regular-pink;
  }

  .divider-label {
    font-size: 1.2rem;
    width: auto;
    position: absolute;
    top: .25rem;
    background-color: $dark-grey;
    padding-left: 1rem;
    padding-right: 1rem;
    font-family: 'Anonymous Pro', monospace;
  }

  .top-divider, .bottom-divider {
    p.next {
      left: 0;
    }
    p.previous {
      right: 0;
    }
  }

  a {
    color: $regular-pink;
  }

  .button {
    cursor: pointer;
    transition: all 0.2s ease-out;
    &:hover {
      box-shadow: 0 1px 3px rgba(0,0,0,0.25);
    }
    &:active {
      box-shadow: 0 1px 3px rgba(0,0,0,0.25) inset;
    }
  }

  .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    visibility: hidden;
    transition: opacity 250ms;
    opacity: 0;
    &.light {
      z-index: 1;
      background: rgba(20, 17, 20, 0.8);
    }
    .cancel {
      position: absolute;
      width: 100%;
      height: 100%;
      cursor: default;
    }
    &:target {
      visibility: visible;
      opacity: 1;
    }
  }

  .popup {
    margin: 190px auto;
    padding: 20px;
    background: $dark-grey;
    border: 4px solid $regular-pink;
    box-shadow: 0 0 50px rgba(0,0,0,0.5);
    position: absolute;
    z-index: 1;
    p {
      color: $white;
      font-family: 'Anonymous Pro', monospace;
      font-size: 1.35rem;
    }
    b {
      font-size: 2.5rem;
      background-color: $regular-pink;
      padding: 5px 10px;
    }
    #img-container {
      width: 150px;
      height: 150px;
      float: right;
      #cat {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
    #news {
      border: 3px solid $regular-pink;
      margin-left: -1px;
      border-radius: 10px;
      width: auto;
      max-width: 80%;
      height: auto;
    }
  }

  .popup-mobile {
    margin: 135px auto;
    padding: 20px;
    width: 95%;
    background: $dark-grey;
    border: 4px solid $regular-pink;
    box-shadow: 0 0 50px rgba(0,0,0,0.5);
    position: absolute;
    z-index: 1000;
    p {
      color: $white;
      font-family: 'Anonymous Pro', monospace;
      font-size: 1.25rem;
    }
    b {
      font-size: 2.1rem;
      background-color: $regular-pink;
      padding: 5px 10px;
    }
    #img-container-mobile {
      width: 100px;
      height: 100px;
      float: right;
      #cat-mobile {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
    #news-mobile {
        border: 3px solid $regular-pink;
        border-radius: 10px;
        margin-left: -1px;
        width: auto;
        max-width: 100%;
        height: auto;
    }
  }
}
</style>

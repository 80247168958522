<template>
  <div id="sidebar">
    <p class="logo-branding mb-0">
      Egirl
    </p>
    <p class="logo-branding">
      Capital
    </p>
    <ul class="mt-5 pl-0">
      <li>
        <router-link
          :to="{name:'Home'}"
          active-class="active-sidebar"
          exact
        >
          00.home
        </router-link>
      </li>
      <li>
        <router-link
          :to="{name:'Writings'}"
          active-class="active-sidebar"
        >
          01.writings
        </router-link>
      </li>
      <li>
        <router-link
          :to="{name:'Portfolio'}"
          active-class="active-sidebar"
        >
          02.portfolio
        </router-link>
      </li>
      <!-- <li>
        <router-link
          :to="{name:'Art Stuff'}"
          active-class="active-sidebar"
        >
          03.art stuff
        </router-link>
      </li> -->
      <!-- <li>
       <router-link
         :to="{name:'Magazines'}"
         active-class="active-sidebar"
       >
         04.magazines
       </router-link>
      </li> -->
      <li v-if="debug">
        <a
          href="javascript:void(0)"
          @click="clearVuex">
          @1.clear vuex
        </a>
      </li>
    </ul>
    <div class="row mt-5 egirl-contact">
      <div class="col-12">
        <a href="mailto:contact@egirlcapital.com">
          <b-icon icon="envelope"/> contact@egirlcapital.com
        </a>
      </div>
      <div class="col-12">
        <a
          href="https://twitter.com/egirl_capital"
          target="_blank"
        >
          <b-icon icon="twitter"/> @egirl_capital
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Sidebar',
  methods: {
    clearVuex () {
      localStorage.clear()
    }
  },
  computed: {
    debug () {
      return process.env.VUE_APP_DEBUG === 'true'
    }
  }
}
</script>

<style scoped lang="scss">
@import '../../../src/styles/_variables.scss';

#sidebar {
  font-family: 'Anonymous Pro', monospace;
  position: fixed;

  .logo-branding {
    font-family: 'Oswald', sans-serif;
    font-size: 5rem;
    line-height: 5rem;
    text-transform: uppercase;
    padding-left: 19px;
    border-left: 5px solid $regular-pink;

    &:first-child {
      margin-top: .2rem;
    }
  }

  ul>li {
    display: block;

    a {
      padding: 8px 24px;
      font-size: 2rem;
      color: $white;

      &:hover {
        text-decoration: none;
        padding-left: 19px;
        border-left: 5px solid $regular-pink;
      }
    }
  }

  .egirl-contact {
    a {
      color: $white;
      padding-left: 24px;

      &:hover {
        text-decoration: none;
        padding-left: 19px;
        border-left: 5px solid $regular-pink;
      }
    }
  }
}

.active-sidebar {
  transition-property: background-color;
  transition-duration: 0.1s;
  transition-timing-function: ease-in-out;
  background-color: $regular-pink;
}
</style>

<template>
  <div id="app" class="container-fluid">

    <Topbar
      @showMobileNavigation="showMobileNavigation(true)"
    />
<!--    <Midcut/>-->
    <div class="row">
      <div class="col-2 pl-0 d-none d-xl-block">
        <Sidebar/>
      </div>
      <div class="col-auto">

      </div>
      <div class="col-12 col-xl-8 align-self-right">
        <transition
          name="fade"
          mode="out-in"
        >
          <router-view/>
        </transition>
      </div>
    </div>
    <transition
      name="fade"
      mode="out-in">
      <NavOverlay
        v-if="showOverlay"
        @closeOverlay="showMobileNavigation(false)"
        @moveUser="moveUser($event)"
      />
    </transition>

    <div v-konami="easterEgg"></div>
  </div>
</template>

<script>
import Sidebar from './components/navigation/Sidebar'
import Topbar from '@/components/navigation/Topbar'
// import Midcut from './components/Midcut'
import NavOverlay from '@/components/navigation/NavOverlay'

export default {
  name: 'EgirlGlue',
  components: {
    Sidebar,
    // Midcut,
    Topbar,
    NavOverlay
  },
  data () {
    return {
      showOverlay: false
    }
  },
  methods: {
    showMobileNavigation (state) {
      this.showOverlay = state
    },
    moveUser (name) {
      this.$router.push({name: name})
      window.setTimeout(() => {
        this.showMobileNavigation(false)
      }, 100)
    },
    easterEgg () {
      window.location.href = "https://www.youtube.com/watch?v=dQw4w9WgXcQ"
    }
  }
}
</script>

<style lang="scss">
@import './styles/_variables.scss';

@at-root html {
  background-color: $dark-grey;
}

#app {
  font-family: 'Noto Sans JP', sans-serif;
  color: $white;
  background-color: $dark-grey;
  min-height: 100vh;
  max-height: 100%;
  ::selection {
    color: $white;
    background-color: $light-pink;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition-duration: 0.2s;
    transition-property: opacity;
    transition-timing-function: ease;
  }

  .fade-enter,
  .fade-leave-active {
    opacity: 0;
  }
}

</style>

<template>
  <div
    id="topnav"
    class="sticky-top container-fluid pl-0 d-xl-none d-lg-none d-sm-block"
    @click="showMobileNavigation"
  >
  <div class="row location pl-3">
    <div class="col">
      {{ location }}
    </div>
    <div class="col text-right">
      <b-icon icon="list"/>
    </div>
  </div>
  </div>
</template>

<script>
export default {
  name: 'TopbarNavigation',
  methods: {
    showMobileNavigation() {
      this.$emit('showMobileNavigation')
    }
  },
  computed: {
    location () {
      switch (this.$route.name) {
        default:
        case 'Home':
          return '00.home'
        case 'Writings':
        case 'Writing':
          return '01.writings'
        case 'Portfolio':
        case 'Investment':
          return '02.portfolio'
        // case 'Art Stuff':
        //   return '03.art stuff'
        // case 'Magazines':
        // case 'Magazine':
        //   return '04.magazines'
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '../../styles/_variables';
#topnav {
  font-family: 'Anonymous Pro', monospace;
  font-size: 1.8rem;
  background-color: $dark-grey;

  .location {
    border-left: 3px solid $regular-pink;
  }

  .menu-button {
    background-color: $regular-pink;
  }
}
</style>